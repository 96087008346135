<template>
    <div class="login">
        <div class="container">

            <LogoTop />

            <div class="row">
                <div class="col-lg-6 d-none d-sm-none d-lg-block">                
                    <img src="@/assets/images/padlock-image.svg" style="top: -90px; z-index: -1;">
                </div>

                <div class="col-lg-5 offset-lg-1 col-sm-8 offset-sm-2 col-12 form-cover">
                    <h1 class="welcome-h1 mb-5">Reset your<br>Password</h1>

                    <form class="login-form reset-pasword" autocomplete="off" method="post" v-on:submit.prevent="handlePassword" >
                        <div class="form-group">
                            <label class="bolder">Username:</label>
                            <input type="text" class="form-control" placeholder="Enter your username" required v-model="reset.username">
                        </div>

                        <div :class="msg_status" v-html="msg_content"></div>
                        <button type="submit" class="btn form-control login-btn" :disabled='isDisabled'>{{processBtn}}</button>

                        <div class="text-center mt-3">
                            <p><a href="/"><small>Back to Sign In</small></a></p>
                        </div>
                    </form>
                </div>
            </div>
            
            <Copyright />

        </div>
    </div>
</template>


<script>
import LogoTop from '@/components/LogoTop.vue';
import Copyright from '@/components/Copyright.vue';
import axios from 'axios';

export default {
    name: "ForgotPassword",
    components: {
        LogoTop, Copyright
    },
    
    data() {
        return{
            isDisabled: false,
            processBtn: 'SEND RESET LINK',
            msg_status: '',
            msg_content: '',
            reset:{}
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "Forgot Password - Enter your Customer ID";
            },
        },
    },

    methods: {
        async handlePassword () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';

                if(this.reset.username != ''){
                    this.isDisabled = true,
                    this.processBtn = 'Processing...';

                    let data = this.loadNew(this.reset);
                    await axios({
                        method: "post",
                        url: this.$baseurl + "/reset-password",
                        data: {'token':data},
                        headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message;
                            this.processBtn     = 'Loading...';       
                                            
                            this.isDisabled     = false;
                            this.processBtn     = 'SEND RESET LINK';

                            //this.$router.push('/reset-password-success');

                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = this.response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'SEND RESET LINK';
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    this.is401(error.response.status);
                    })  

                    .finally(e => {                                       
                        this.isDisabled = false;
                        this.processBtn = 'SEND RESET LINK';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "Enter your username to continue.";
                }
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },
    }
};
</script>

<style scoped></style>