<template>
    <div class="row mb-5">
        <!-- <div class="text-center color-scope bold mt-5 font-12" style="font-weight: 500;">
            <p><b>FMN</b> - Feeding The Nation Everyday!</p>
        </div> -->

        <div class="col text-center mb-5 mt-5 copyright-area">
            Copyright &copy;{{ new Date().getFullYear() }} - FMN Plc. All rights reserved. <br />
             <!-- &nbsp;&nbsp;|&nbsp;&nbsp; -->
            <a href="#!">Privacy Policy</a> &nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="#!">Terms of Use</a>
        </div>
    </div>
</template>

<script>
  export default { name: "Copyright" };
</script>