<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><a href="/products" title="Go back to profile"><i class="fa fa-arrow-left text-dark"></i></a> &nbsp;&nbsp;&nbsp;&nbsp;  <b>Product Details</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/products">Products</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Product Details</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>



                <div class="row mt-5 mb-5">
                    <div class="col-lg-3 text-center">
                        <img :src="'../'+product_details.image_path+product_details.display_image" style="width:130px"><br>
                        <img :src="'../'+product_details.image_path+product_details.display_image" style="width:130px">
                    </div>

                    <div class="col-lg-4">

                        <div class="p-3 text-center border shadow-sm mb-3">
                            
                            <i class="fa fa-angle-left bg-scope border text-white rounded-circle" style="padding:5px 9px"></i>
                        <img :src="'../'+product_details.image_path + product_details.display_image" style="width:200px">
                        
                            <i class="fa fa-angle-right bg-scope border text-white rounded-circle" style="padding:5px 9px"></i>
                        </div>

                        <!-- <p class="font-12 bolder">Golden Penny Pasta is Nigeria’s no. 134 pasta made from thefinest quality rrot durum which makes.</p> -->
                    </div>

                    <div class="col-lg-2 offset-lg-1">
                        <p class="color-scope bolder">{{product_details.productname}}</p>
                        <div class="font-12">
                            <span class="shadow bolder p-2" style="margin-right:15px;"><b>&#x20A6;{{product_details.price}}</b></span> 
                            <!-- <s>&#x20A6;234,000</s> -->

                            <br><br>

                            <!-- <span style="margin-left:80px">0 Reviews</span> -->
                        </div>

                        <div class="mt-4">
                            <button class="rounded-pill btn-sm btn bg-scope text-white shadow" @click="decrement(product_details.ID)">
                                <i class="fa fa-minus bold"></i>
                            </button>

                            <input  :value="unitCount(product_details.ID)"  v-on:keyup="setUnit(product_details.ID)" type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="input-control" style="width: 100px; margin: 0 5px; text-align:center;outline: none;border: 1px solid #f2f2f2;" :id="'item_'+product_details.ID">
                            
                            <button class="rounded-pill btn-sm btn bg-scope text-white shadow" @click="increment(product_details.ID)">
                                <i class="fa fa-plus bold"></i>
                            </button>
                        </div>

                        <!-- <div class="mt-3">
                            <button class="btn bg-secondary border rounded-pill bolder btn-sm" style="margin-right:10px">1000g</button>
                            <button class="border rounded-pill btn btn-sm">500g</button>
                        </div> -->

                        <div class="mt-3">
                            <!-- <button class="btn bg-secondary border rounded-pill bolder font-12 form-control" style="padding:12px"> ADD TO CART </button> -->
                        </div>
                    </div>
                    
                    <div class="col-lg-1 text-center">
                        <span class="wish-btn">
                            <i class="fa fa-heart"></i>
                        </span>
                    </div>
                </div>


                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';


export default {
    name: "Dashboard",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"products",
            product_id:'',
            product_details:{},
            obj_product:{},
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    computed: {
        cartitem() {
            return this.$store.getters.cart;
        },        
        cartarray (){
            return this.$store.getters.cartdata;
        }
    },
    
    mounted () {      
        this.inactivityTime();   
        this.product_id = this.$route.params.id;
        this.viewProduct();
    },

    methods: {
        async viewProduct () {
            try {
                this.obj_product.fn = 'viewProduct';
                this.obj_product.id = this.product_id;
                    
                this.obj_product.subaccountID = this.$store.getters.sub_acct;

                    let data = this.loadNew(this.obj_product);             
                await axios({
                    method: "post",
                    url: "https://fmnplc.website/fmn/app_data.php",
                    data: {'token':data},
                    headers: { "Content-Type": "multipart/form-data" },
                })

                .then(res => {
                    this.product_details = res.data[0];        
                    console.log(this.product_details)       
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })  

                .finally(e => {                 
                    console.log(e);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        removeFromCart(x){
            const index = this.cartitem.indexOf(x);
            let data = this.cartitem;
            if (index > -1) {data.splice(index, 1);}


            let cartarr = this.cartarray.filter( el => el.id !== x ); 


            this.$store.commit("updateCart", data);
            this.$store.commit("updateCartdata", cartarr);
        },

        increment(x){
            let input  = Number(document.getElementById('item_'+x).value) + 1;
            document.getElementById('item_'+x).value = input;
            this.updateCartQuantity (input, x, '+');
        },

        setUnit(x){
            let input = Number(document.getElementById('item_'+x).value);
            input > 0 ? document.getElementById('item_'+x).value = input : this.removeFromCart(x);            
            this.updateCartQuantity (input, x, '');
        },

        decrement(x){
            let input = Number(document.getElementById('item_'+x).value) - 1;
            input > 0 ? document.getElementById('item_'+x).value = input : this.removeFromCart(x);            
            this.updateCartQuantity (input, x, '-');
        },

        updateCartQuantity (value, product_id, action) {
            let cart_array = this.$store.getters.cartdata;
            cart_array.forEach(i => {
                if (product_id == i.id) {
                    if (action == '+') {
                        i.unit = parseInt(i.unit) + 1;
                    }
                    else if (action == '-') {
                        i.unit = parseInt(i.unit) - 1;
                    }
                    else {
                        i.unit = value;
                    }
                }
            })  

            this.$store.commit("updateCartdata", cart_array);
        },        

        unitCount(x){            
            return this.cartarray.filter( el => el.id == x )[0].unit;
        },

    },
};
</script>

<style scoped>
.wish-btn {
    color: #fff;
    background: #006e40;
    padding: 6px 7px;
    border-radius: 50px;
    /* line-height: 14px; */
    /* font-size: 14px; */
}
</style>

