<template>
    <div class="container-fluid">
        <div class="row">
            
            <DashboardNav v-bind:page_active="page_active"/>

            <div class="main-section">
               
                
                <!-- greetings -->
                <TimeGreetings />

                <div class="content-body" style="top: 0px;">
                    <!-- page breadcrumb -->
                    <div class="row breadcrumb-area">
                        <div class="col-lg-6">
                            <!-- <h6><b>Account Statement</b> - {{account_id}}</h6> -->
                        </div>

                        <div class="col-lg-6">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/home">Dashboard</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Profile</li>
                                </ol>
                            </nav>                   
                        </div>
                    </div>

                    <!-- Tablular Analytics transaction table -->                
                    <ProfileComponent />

                    <Copyright />
                </div>
            </div>
        </div>
    </div>
</template>




<script>
import DashboardNav from '@/components/DashboardNav.vue';
import Copyright from '@/components/Copyright.vue';
import ProfileComponent from '@/components/ProfileComponent.vue';
import TimeGreetings from '@/components/TimeGreetings.vue';


export default {
    name: "ProfileView",
    components: {
        DashboardNav, Copyright, ProfileComponent, TimeGreetings
    },
    
    data() {
        return{       
            page_active:"profile",     
            account : {
                customername: '',
                telephone: "",
            },
            account_id:'',
            subprofiles:[{"customername":""}],
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Account Statement";
            },
        },
    },

    mounted () {                
        this.inactivityTime(); 
        this.subprofiles =  this.$store.getters.subaccounts;

        this.account_id = this.$route.params.id;
        this.account = this.$store.getters.subaccounts.find((c) => c.CustomerAccount == this.account_id );
    },
};
</script>

<style scoped>
label{
    position: absolute;
    top: -15px;
    font-weight: 500;
    font-size: 12px;
}
.login-form .form-check-input {
    border: 2px solid #14142B;
    border-radius: 8px !important;
}
ul {
    padding-left: 10px;
    margin-bottom: 0;
}
.side-nav-bg-cover {
    width: 30px;
    left: 0px;
    /* border-radius: 24px 0 0 24px; */
    /* top: 20px; */
    /* bottom: 10px; */
    /* height: auto !important; */
}
.side-nav {
    width: 80px;
    /* border-radius: 17px 25px 25px 17px; */
    /* top: 20px; */
    /* bottom: 10px; */
    /* left: 25px; */
}
.side-link.active > a {
    padding: 0 5px 0 25px;
}
.side-nav ul li a {
    color: #fff;
    display: block;
    padding: 0 5px 0px 25px;
}
.main-section {
    width: calc(100% - 80px);
    margin-left: 80px;

    /* background: url(https://demo.fmnplc.website/product_images/236-2369779_flour-png-wheat-flour-bag-png.png); */
    background-position: 45% 70%;
    /* background-size: cover; */
    /* background-repeat: no-repeat; */
}
.side-link-after {
    padding: 15px 0px 20px !important;
}
/* .subprofile .card-body.active {
    border: 2px solid rgba(255, 255, 255, 0);
} */
.subprofile .card-body {
    box-shadow: none;
}
.dashboard-icon1{
 font-size: 24px;
    background: #000;
    padding: 12px 14px;
    border-radius: 25px;
    color: #ffd300;
    box-shadow: 1px 2px 6px 6px #f2f2f2;
    top: -5px;
}
.btn{
    background: #000;
    color: #fff;
}
.form-check-label {
    font-weight: 600;
    font-size: 12px;
}
.h1 {
    font-size: 26px;
    font-weight: 600;
}

.side-link.active, .container-fluid {
    background: #f6f6f6 !important;
}

.card{
    background: #fff;
}
</style>

