<template>
    <div class="login">
        <div class="container">

            <LogoTop />

            <div class="row">
                <div class="col-12 col-lg-10 offset-lg-1 form-cover">
                    <h1 class="welcome-h1 mb-5 alert alert-success text-center">
                        <i class="fa fa-check check-correct"></i> Password Reset Was Successful
                    </h1>

                    <hr>

                    <p class="mt-5 mb-5">
                        Awesome! We have sent a link containing your password reset to your registered email address. Kindly open the link to continue.
                    </p>

                    <div class="text-center mt-5 bolder">
                        <p class="mt-5 font-12"><a href="/" class="color-scope"><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;&nbsp;&nbsp; Back to Login</a></p>
                    </div>

                </div>
            </div>
            
            <Copyright />

        </div>
    </div>
</template>


<script>
import LogoTop from '@/components/LogoTop.vue';
import Copyright from '@/components/Copyright.vue';

export default {
    name: "ForgotPassword",
    components: {
        LogoTop, Copyright
    },
    
    data() {
        return{
            isDisabled: false,
            processBtn: 'SEND RESET LINK',
            msg_status: '',
            msg_content: '',
            reset:{}
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "Forgot Password - Enter your Customer ID";
            },
        },
    },

    methods: {

    }
};
</script>

<style scoped>
.check-correct {
    color: #f8dc58;
    background: #336740;
    padding: 15px;
    border-radius: 50px;
    margin-right: 23px;
}
</style>