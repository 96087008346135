<template>
    <div class="login">
        <div class="container">

            <LogoTop />

            <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <h1 class="welcome-h1">OTP<br>Confirmation</h1>
                    <p class="col-lg-10 col-12 otp-msg"><b>Please enter the OTP CODE sent to your registered phone number: {{phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}} and to your email address: {{email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")}}</b></p>
                    
                    <form class="subprofile login-form mt-5" autocomplete="off" v-on:submit.prevent="handleOTP">
                        <input v-model="otp.otp1" autofocus type="tel" class="otp-input" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  minlength="1" maxlength="1" onKeyUp="document.getElementById('inp2').focus();" id="inp1">

                        <input v-model="otp.otp2" type="tel" class="otp-input" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  minlength="1" maxlength="1" onKeyUp="document.getElementById('inp3').focus();" id="inp2">

                        <input v-model="otp.otp3" type="tel" class="otp-input" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  minlength="1" maxlength="1" onKeyUp="document.getElementById('inp4').focus();" id="inp3">

                        <input v-model="otp.otp4" type="tel" class="otp-input" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  minlength="1" maxlength="1" id="inp4">
                        
                        
                        <div class="col-lg-10 col-12" :class="msg_status" v-html="msg_content"></div>

                        <button type="submit" class="btn login-btn col-lg-8 mt-5 col-11" :disabled='isDisabled'>{{processBtn}}</button>
                    </form>

                    <p class="col-lg-10 col-12 mt-5 font-12 bolder" v-if="!isVerify"><small>Did not receive OTP, <a href="#!" @click="handle_resendOTP">Click here to resend</a></small></p>

                </div>

                <div class="col-lg-6 d-none d-sm-none d-lg-block">                
                    <img src="@/assets/images/side-laptop.svg">
                </div>
            </div>       

            <Copyright />
        </div>
    </div>
</template>




<script>
import LogoTop from '@/components/LogoTop.vue';
import Copyright from '@/components/Copyright.vue';
import axios from 'axios';

export default {
    name: "Registration",
    components: {
        LogoTop, Copyright
    },
    
    data() {
        return{
            isDisabled: false,
            processBtn: 'DONE',
            msg_status: '',
            msg_content: '',
            otp:{},
            phone:" ",
            email:" ",
            otp_data:{},
            isVerify:false,
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN E-Commerce | OTP Validation";
            },
        },
    },

    mounted() {
        if (this.$store.getters.vendor_no) {
            this.vendor_no  = this.$store.getters.vendor_no; 
            this.phone      = this.$store.getters.phone;      
            this.email      = this.$store.getters.email;             
        }       
    },

    methods: {
        async handleOTP () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';

                if(this.otp.otp1 != undefined && this.otp.otp2 != undefined && this.otp.otp3 != undefined && this.otp.otp4 != undefined){   
                    this.isDisabled = true,
                    this.processBtn = 'Processing...'; 

                    this.otp_data.newotp    = this.otp.otp1+''+this.otp.otp2+''+this.otp.otp3+''+this.otp.otp4;
                    this.otp_data.vendor_no = this.vendor_no;


                    let data = this.loadNew(this.otp_data);
                    await axios({
                        method: "post",
                        url: this.$baseurl + "/verify-otp",
                        data:{'token':data},
                        headers: { "Content-Type": "application/json" },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message + '<a href="/">Click here to Log In</a>';
                            this.processBtn     = 'VERIFIED';
                            
                            this.isVerify = true;
                            // this.$router.push('/');

                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = this.response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'DONE';
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    this.is401(error.response.status);
                    })  

                    .finally(e => {                                       
                        this.isDisabled = false;
                        this.processBtn = 'DONE';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status = 'alert alert-danger mt-4';
                    this.msg_content= "Enter your OTP";
                }
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },
        
        async handle_resendOTP () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';
 
                this.otp_data.vendor_no = this.vendor_no;
                this.otp_data.phone     = this.phone;
                this.otp_data.fn        = 'resendOtp';

                this.isVerify = true;

                    let data = this.loadNew(this.otp_data);
                await axios({
                    method: "post",
                    url: this.$baseurl + "/resend-otp",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json" },
                })

                .then(res => {
                    this.response = res.data

                    
                    this.isVerify = false;

                    if(this.response.status == true){
                        this.msg_status     = 'alert alert-success mt-4';
                        this.msg_content    = this.response.message;
                    }
                    else{
                        this.msg_status     = 'alert alert-danger mt-4';
                        this.msg_content    = this.response.message;
                    }                        
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })  

                .finally(e => {
                    console.log(e);
                })
            
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },
    }
};
</script>

<style scoped>
.alert.alert-danger{
    font-size: 12px;
    font-weight: 600;
}
</style>

