<template>
    <div class="container-fluid"  v-if="subprofiles.length > 0">
        <div class="row">
            <DashboardNav v-bind:page_active="page_active"/>

            <div class="main-section">
                <TimeGreetings />

                <!-- list all accounts -->
                <form class="subprofile login-form row" autocomplete="off" method="post" v-on:submit.prevent="handleProfile">
                    <div class="col-lg-3 mb-3 card subprofile-lists" v-for="(x,i) in subprofiles" :key="i">

                        <label class="p-3 col-12 subprofile-lists-item" :for="'box'+x.CustomerAccount" :class="x.CustomerAccount == selected_acct ? 'active' : ''" @click="selected_acct = x.CustomerAccount; subacct_bal = x.CreditRemaining; subacct_name = x.OrganizationName">

                            <input class="form-check-input" type="radio" name="exampleRadios" value="" :id="'box'+x.CustomerAccount">

                            <label class="form-check-label font-14 col-11" :for="'box'+x.CustomerAccount">                              
                                <b>Account: {{x.CustomerAccount}}</b><br> Name: {{x.OrganizationName}}<br> Balance: &#x20A6;{{thDFormat(x.CreditRemaining)}}<br><br>
                            </label>

                            <u>
                                <a href="#!" @click="account_selected = x.CustomerAccount" data-bs-toggle="modal" data-bs-target="#daterange">View Statement</a>
                            </u>
                            
                            <button type="submit" class="btn text-white" title="Shop with Account" style="border-radius: 5px; bottom: 10px;position: absolute;right: 10px;background:#000">
                                <svg class="cart_icon" width="20" height="25" viewBox="0 0 32 32" fill="#f9d933" xmlns="http://www.w3.org/2000/svg"><path d="M10.3101 20.6877H10.3115C10.3127 20.6877 10.314 20.6875 10.3152 20.6875H27.3125C27.731 20.6875 28.0989 20.4099 28.2139 20.0076L31.9639 6.88257C32.0447 6.59961 31.988 6.29541 31.811 6.06055C31.6338 5.82568 31.3567 5.6875 31.0625 5.6875H8.14795L7.47778 2.67163C7.38233 2.24268 7.00195 1.9375 6.5625 1.9375H0.9375C0.419678 1.9375 0 2.35718 0 2.875C0 3.39282 0.419678 3.8125 0.9375 3.8125H5.81055C5.9292 4.34692 9.01758 18.2449 9.19531 19.0444C8.19898 19.4775 7.5 20.4709 7.5 21.625C7.5 23.1758 8.76172 24.4375 10.3125 24.4375H27.3125C27.8303 24.4375 28.25 24.0178 28.25 23.5C28.25 22.9822 27.8303 22.5625 27.3125 22.5625H10.3125C9.79566 22.5625 9.375 22.1418 9.375 21.625C9.375 21.1089 9.79419 20.689 10.3101 20.6877ZM29.8196 7.5625L26.6052 18.8125H11.0645L8.56445 7.5625H29.8196Z" fill="#f9d933" /> <path  d="M9.375 27.25C9.375 28.8008 10.6367 30.0625 12.1875 30.0625C13.7383 30.0625 15 28.8008 15 27.25C15 25.6992 13.7383 24.4375 12.1875 24.4375C10.6367 24.4375 9.375 25.6992 9.375 27.25ZM12.1875 26.3125C12.7043 26.3125 13.125 26.7332 13.125 27.25C13.125 27.7668 12.7043 28.1875 12.1875 28.1875C11.6707 28.1875 11.25 27.7668 11.25 27.25C11.25 26.7332 11.6707 26.3125 12.1875 26.3125Z" fill="#f9d933" /><path d="M22.625 27.25C22.625 28.8008 23.8867 30.0625 25.4375 30.0625C26.9883 30.0625 28.25 28.8008 28.25 27.25C28.25 25.6992 26.9883 24.4375 25.4375 24.4375C23.8867 24.4375 22.625 25.6992 22.625 27.25ZM25.4375 26.3125C25.9543 26.3125 26.375 26.7332 26.375 27.25C26.375 27.7668 25.9543 28.1875 25.4375 28.1875C24.9207 28.1875 24.5 27.7668 24.5 27.25C24.5 26.7332 24.9207 26.3125 25.4375 26.3125Z" fill="#f9d933" /></svg>
                            </button>
                            
                        </label>
                    </div>
                </form>


                <!-- Analytics -->
                <div class="row">

                    <!-- Pie Chart: Account Breakdown -->
                    <div class="col-lg-6 account-breakdown">
                        <div class="card shadow-sm">

                            <div class="card-header">
                                <b>Account Breakdown</b>
                            </div>

                            <div class="card-body">      

                                <!-- import morris-graph ui -->
                                <donut-chart id="donut" :data="donutData" :colors="randombg" resize="true"></donut-chart>


                                <!-- design keys -->
                                <div class="donut-keys">
                                    <div  v-for="(x,i) in donutData" :key="i">

                                        <span :style="'background:'+ randombg[i]" class="key-items"></span>
                                        <b class=" font-12">{{ x.label }} - {{ Math.round(100/(totalBalance/x.value)) }}%</b>

                                    </div>
                                </div>


                                <!-- amount area -->
                                <div class="grand-total">
                                    <h1>

                                        <span>Grand Total:</span><br>

                                        <span class="figure">
                                            &#x20A6;{{thDFormat(totalBalance)}}
                                        </span>

                                    </h1>
                                </div>

                            </div>
                        </div>
                    </div>


                    <!-- Bar Chart: Open Orders -->
                    <div class="col-lg-6 open-orders">
                        <div class="card shadow-sm">
                            <div class="card-header">
                                <span style="font-weight:600">Open Orders Per Account</span>
                            </div>

                            <!-- import morris-barchart -->
                            <div class="card-body">
                                <bar-chart id="bar" :data="barData" :bar-colors="arrayColors" :xkey="'account'" :ykeys="ykeys" grid="true" resize="true" grid-text-weight="bold" ></bar-chart>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- import footer -->
                <div class="row bg-white">
                    <Copyright />
                </div>


                <!-- Modal -->
                <div class="modal fade" id="daterange" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog mt-5 daterange" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Select date range</h5>

                                <button type="button" class="close btn" style="background:transparent" data-bs-dismiss="modal" aria-label="Close">
                                    <i class="fa fa-times text-danger"></i>
                                </button>
                            </div>
                            <div class="modal-body p-3">
                                <div class="form-group">
                                    <b>Start Date:</b>
                                    <input type="date" class="form-control p-3" v-model="date_range.start_date">
                                </div>

                                <div class="form-group mt-3">
                                    <b>End Date:</b>
                                    <input type="date" class="form-control p-3" v-model="date_range.end_date">
                                </div>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn text-white bg-scope" data-bs-dismiss="modal" @click="viewStatement()">View Statememt</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    </div>
</template>

<script>
import DashboardNav from '@/components/DashboardNav.vue';
import TimeGreetings from '@/components/TimeGreetings.vue';
import Copyright from '@/components/Copyright.vue';
import {BarChart} from 'vue-morris';
import { DonutChart } from 'vue-morris';
import axios from 'axios';
import JQuery from 'jquery';
window.$ = JQuery;

export default {
    name: "Home",
    components: {
       Copyright, BarChart, DonutChart, DashboardNav, TimeGreetings
    },

    data() {
        return{
            page_active:'dashboard',
            subprofiles:[],
            selected_acct:0,
            subacct_bal:0,
            subacct_name:'',
            randombg:[],         
            arrayColors:["#006e40"],
            barData: [{ account: '', "amount": 0}],
            donutData: [],
            ykeys:['amount'],
            xkey:['account'],            
            date_range:{},
            account_selected:'',
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted () {        
        this.inactivityTime(); 
        // this.subprofiles =  this.$store.getters.subaccounts;

        this.$store.commit("updateCart", []);
        this.$store.commit("updateCartdata", []);
        this.fetchAccountBalance();
    },

    computed: {
        totalBalance(){
            let sum = 0;
            for(let i = 0; i < this.subprofiles.length; i++){
                sum +=  Number(this.subprofiles[i].CreditRemaining);
            }
            return sum;
        }
    },

    methods: {
        fetchAccountBalance(){
            if(this.$store.getters.subaccounts.length === 0){
                this.$router.push('/');
                return;
            }

            console.log(this.$store.getters.subaccounts[0].CustomerAccount, this.$store.getters.vendor_no)

            let data = this.loadNew({"subaccountID": this.$store.getters.vendor_no});
            
            axios({
                method: "get",
                url: this.$hostname+"/getsubaccountID",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                this.$store.commit("set_subaccounts", res.data.data);
                this.subprofiles =  this.$store.getters.subaccounts;

                let x = this.subprofiles;  
                this.pet = 0;        
                this.donutData = [];   
                this.barData = [];

                for(let i = 0; i < x.length; i++){
                    // Generate random colors
                    var color =  Math.round(this.pet + (210 / x.length));
                    this.randombg.push("#00"+Number(color).toString(16)+"20");
                    this.pet = color;

                    // barchart UI data
                    let bar_obj = {};
                    bar_obj.account = x[i].CustomerAccount;
                    bar_obj.amount  = x[i].Ordered;
                    this.barData.push(bar_obj);

                    // donutchart UI data
                    let donut_obj = {};
                    donut_obj.label    = x[i].CustomerAccount;
                    donut_obj.value  = x[i].CreditRemaining < 0 ? 0 : x[i].CreditRemaining;
                    this.donutData.push(donut_obj);
                }

                // OrderBy Desc
                this.donutData.sort(function(a, b){return b['value'] - a['value']})
            })
        },

        handleProfile () {
            if(this.selected_acct != 0){
                
                // store selections in localStorage
                this.$store.commit("set_sub_acct", this.selected_acct);
                this.$store.commit("set_sub_acct_bal", this.subacct_bal);
                this.$store.commit("set_sub_acct_name", this.subacct_name);

                // open mode-of-delivery screen
                this.$router.push('/mode-of-delivery');
            }        
        },     
        
        viewStatement(){
            if(this.date_range.start_date !== undefined && this.date_range.end_date !== undefined){
                this.$store.commit("set_account_statement_startdate", this.date_range.start_date);
                this.$store.commit("set_account_statement_enddate", this.date_range.end_date);
                this.$router.push('account-preview/'+this.account_selected);
            }
            else{
                this.$swal.fire(
                    'Alert',
                    'Please enter start date and end date.',
                    'error'
                )
            }
        },

        describeAccount(x){
            let info  = x.split('-')[1];
            
            let rules = '';
            if(info != undefined ){
                rules = info.charAt(0)
            }

            if(rules == 'B'){
                return "Business To Business Account";
            }
            else if(rules == 'C'){
                return "Business To Customer Account";
            }
            else{
                return "Business Account";
            }
        }
          
    },

};
</script>




<style scoped>
.login-form .form-check-input { border: 2px solid #14142B; border-radius: 8px !important;height: 1px;width: 1px;}
ul {padding-left: 10px; margin-bottom: 0;}
.side-nav-bg-cover {width: 30px;left: 0px;}
.side-nav {width: 80px;}
.side-link.active > a {padding: 0 5px 0 25px;}
.side-nav ul li a {color: #fff;display: block;padding: 0 5px 0px 25px;}
.main-section {width: calc(100% - 80px); margin-left: 80px;background-position: 45% 70%;}
.side-link-after {padding: 15px 0px 20px !important;}
.subprofile .card-body {box-shadow: none;}
.btn{ background: #000; color: #fff;}
.form-check-label {font-weight: 600;font-size: 12px;}
.h1 {font-size: 26px;font-weight: 600;}
.side-link.active, .container-fluid {background: #f6f6f6 !important;}
.card{ background: #fff;}
text{ display:none !important;}
.subprofile{border-bottom: 1px solid #e9e9e9;}
.subprofile-lists{display: block !important; border-left:.25rem solid #006e40; width: calc(25% - 30px);margin: 0 15px !important;padding:0;margin-bottom: 30px !important;}
.subprofile-lists-item{height: 100%;}
.subprofile-lists-item u {bottom: 10px;position: absolute;left: 30px;}
.account-breakdown{border-right: 1px solid #e9e9e9; padding-top:2rem; padding-bottom:2rem}
.account-breakdown .card-body{min-height: 375px;}
.donut-keys{position: absolute;bottom: 40%; left:10px;}
.key-items{height:22px; width:32px;float: left;margin: 0 10px 0 0px;display:block;}
.grand-total{position: absolute;top: 12px;}
.grand-total h1{font-size: 20px; font-weight: 700;}
.grand-total .figure{font-size: 30px;}
.card-header { background-color: rgb(137 137 137 / 3%);}
.open-orders{padding-top:2rem; padding-bottom:2rem}
</style>

