<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>Product</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Product</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>


                <div class="row">                    
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/carton.svg" /> -->
                        <i class="fa fa-heart"></i>

                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>

                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                                        
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/carton.svg" /> -->

                        <i class="fa fa-heart"></i>
                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>
                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                                        
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/carton.svg" /> -->

                        <i class="fa fa-heart"></i>
                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>
                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                                        
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/ball.svg" />  -->
                        <i class="fa fa-heart"></i>

                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>
                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                                        
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/carton.svg" /> -->

                        <i class="fa fa-heart"></i>
                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span> (per carton)<br></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>
                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                                        
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/ball.svg" /> -->

                        <i class="fa fa-heart"></i>
                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>
                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                                        
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/carton.svg" /> -->

                        <i class="fa fa-heart"></i>
                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>
                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                                        
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/carton.svg" /> -->

                        <i class="fa fa-heart"></i>
                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span> (per carton)<br></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>
                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                                        
                    <div class="col-lg-4 text-center mb-4">
                        <div class="shadow-hover">
                        <div class="border product-hover" style="padding:40px">
                        
                        <!-- <img src="@/assets/images/carton.svg" /> -->

                        <i class="fa fa-heart"></i>
                        <p class="bolder">Golden Penny Noodles </p>


                        <p> <span class="color-scope bolder">N 1,000.00</span></p>

                        <p>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star color-scope"></i>
                            <i class="fa  fa-star-half color-scope"></i>
                            (12)
                        </p>
                        <button class="btn bg-secondary shadow border bolder form-control rounded-pill font-12 add-cart-btn p-2"><b>ADD TO CART</b></button>
                        </div>
                        </div>
                    </div>

                </div>



                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';



export default {
    name: "Wishlists",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{            
            page_active: "orders",
            chartdata: {
            labels: ['January', 'February'],
            datasets: [
                {
                label: 'Data One',
                backgroundColor: '#f87979',
                data: [40, 20]
                }
            ]
            },
            options: {
            responsive: true,
            maintainAspectRatio: false
            }
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted () {       
        this.inactivityTime();  
        this.renderChart(this.chartdata, this.options)
    },

    methods: {

    },

    created () {
    }
};
</script>

<style scoped>
.shadow-hover:hover > .product-hover{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 5px;

/* Secondary Colour */
    /* border-bottom : 10px solid #006E40 !important; */
/* Elevation-2 */
/* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); */

    /* box-shadow: inset 0px -9px 0px 0px rgb(0 110 64) !important; */
}

.shadow-hover:hover{
    box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.1);
}



.shadow-hover .add-cart-btn{
    display: block;
     transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all; 
}

.fa.fa-heart{    color: #006e40;
    font-size: 24px;
    right: 25px;
    position: absolute;
    top: 27px;
}
</style>

