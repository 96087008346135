<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">
            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><a href="/" title="Go back to profile"><i class="fa fa-arrow-left text-dark"></i></a> &nbsp;&nbsp;&nbsp;&nbsp;  <b>Select Truck</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Select Truck</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                <div class="row mt-5 mb-5">
                    <div class="col-lg-3 mb-4" v-for="x in trucks" :key="x.ID" :style="isRDCs(x.weight) ? 'display:none' : ''">
                        <div class="border" :class="selected_truck == x.size ? 'truckactive' : ''" @click="selected_truck = x.size">                            
                            <div class="p-4">
                                <img src="@/assets/images/bigtruck.png" style="width:100%" />
                            </div>

                            <div class="bg-scope p-3 font-12 text-white">
                            <b>SIze: {{x.size}}</b><br>
                            <b>Weight: {{ thDFormat(Number(x.weight)) }}kg</b>
                            </div>
                        </div>
                    </div>
                    

                    <div class="col-lg-3 mt-4 offset-lg-9 text-end">                        
                        <div :class="msg_status" v-html="msg_content" class="text-center"></div>
                        <button class="btn bg-secondary btn-large  text-dark bolder p-2 rounded-pill form-control" @click="setTrucks()">Continue</button>
                    </div>

                </div>



                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';



export default {
    name: "SelectTruck",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"orders",
            trucks:[],
            obj_truck:{},
            selected_truck:'',
            msg_status: '',
            msg_content: '',
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Select Truck";
            },
        },
    },

    mounted () {  
        this.inactivityTime();       
        this.loadTrucks();
    },

    methods: {
        async loadTrucks () {
            try {
                this.obj_truck.fn = 'loadTrucks';
                await axios({
                    method: "post",
                    url: "https://fmnplc.website/fmn/app_data.php",
                    data: this.obj_truck,
                    headers: { "Content-Type": "multipart/form-data" },
                })

                .then(res => {
                    this.trucks = res.data;                  
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })  

                .finally(e => {                 
                    console.log(e);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },
        setTrucks (){
            this.msg_status     = '';
            this.msg_content    = '';

            if(this.selected_truck != ''){                
                this.$store.commit("set_selected_truck", this.selected_truck);

                this.$router.push('/products');
            }
            else{
                this.msg_status = 'alert alert-danger mt-4';
                this.msg_content= "Kindly select a truck.";
            }

        },
        isRDCs(x){
            if(Number(x) > 30000 && this.$store.getters.collectionarea != "RDCs"){
                return true;
            }
        }

    },
    

    created () {
    }
};
</script>

<style scoped>
.truckactive{
    border:2px solid #006E40 !important;
}

</style>

