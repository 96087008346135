import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import "../src/assets/css/style.css"
import vSelect from "vue-select";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-select/dist/vue-select.css";
import "jquery";
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
Vue.use(VueSweetalert2);

const { $, jQuery } = require('jquery');
global.$ = $;
global.jQuery = jQuery;

import Raphael from 'raphael/raphael'
global.Raphael = Raphael

Vue.prototype.$hostname = process.env.VUE_APP_BASE_URL+'/api';
Vue.prototype.$baseurl = process.env.VUE_APP_BASE_URL;

// preloader;
Vue.prototype.$preloader = '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100px;" src="https://www.c-sgroup.com/images/loading-icon-red.gif" /><br> <strong> Please Wait... </strong> </div>';

//number to thousands
// with (2) decimals
Vue.mixin({
  methods: {
    thDFormat: function (x) {
      if(x)
       return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else
       return 0
    },
    formatPrice: function (price) {
      if(price){
          var str = price.toString().split(".");
          if (str[0].length >= 3) {
              str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
          }
          if (!str[1]) {
              str[1] = "00";
          }
          return str.join(".");
      }
    },
    inactivityTime: function () {
      var time;
      
      document.onmousemove = resetTimer;
      document.onkeydown = resetTimer;

      function logout() {
        
        location.href = '/?timeout='+true;
        
      }

      function resetTimer() {
          time = setTimeout(logout, (60 * 60 * 1000))
          console.log(time);
      }
    },
    
    is401(x){
      if(x == '401'){
        location.href = '/?sessionexpire='+true;
      }
      if(x == '500'){        
        this.$swal.fire('Error', 'Server error, please contact Admin.', 'error')
      }
    },

    loadNew(txt) {
      var CryptoJS = require("crypto-js");
      const i = '82f2ceed4';
      const ii = 'c503896c8';
      const iii = store.getters.frame;
      const lo = 'sinasi'+store.getters.container;
      const v = i+ii+iii;

      const cipher = CryptoJS.AES.encrypt(JSON.stringify(txt), CryptoJS.enc.Utf8.parse(v), {
        iv: CryptoJS.enc.Utf8.parse(lo),
        mode: CryptoJS.mode.CBC
      })
      return cipher.toString();
    },

    formatDate(date, format) {
      let d = new Date(date)
      if (format == 'time') {
          return d.toLocaleTimeString('en-US');
      }
      else if (format == 'date') {
          return d.toDateString();
      }
      else {
          return d.toDateString() + ' ' + d.toLocaleTimeString('en-US');
      }
    },

  },
})


Vue.config.productionTip = false;


import Bars from 'vuebars'
Vue.use(Bars)
Vue.component("v-select", vSelect)

 
Vue.use(VueAxios, axios)

// mount app
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')