import Vue from 'vue'
import VueRouter from 'vue-router'

// Authentication vue import
import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import Registration from '../views/auth/Registration.vue'
import OTPValidation from '../views/auth/OTPValidation.vue'
import ForgotPasswordSuccess from '../views/auth/ForgotPasswordSuccess.vue'


// Main App vue import
import Home from '../views/utilities/Home.vue'
import Dashboard from '../views/utilities/Dashboard.vue';
import Complaints from '../views/utilities/Complaints.vue';
import ContactUs from '../views/utilities/ContactUs.vue';



import DeliveryMode from '../views/order/DeliveryMode.vue';
import Cart from '../views/order/Cart.vue';
import Orders from '../views/order/Orders.vue';
import Delivery from '../views/order/Delivery.vue';
import Products from '../views/order/Products.vue';
import Wishlists from '../views/order/Wishlists.vue';
import TrackingOrder from '../views/order/TrackingOrder.vue';
import SelectTruck from '../views/order/SelectTruck.vue';
import Checkout from '../views/order/Checkout.vue';
import SalesOrder from '../views/order/SalesOrder.vue';
import ProductDetails from '../views/order/ProductDetails.vue';

// Profile util vue import
import AccountStatement from '../views/account/AccountStatement.vue';
import Wallet from '../views/account/Wallet.vue';
import TransferFund from '../views/account/TransferFund.vue';
import AccountPreview from '../views/account/AccountPreview.vue';
import Profile from '../views/account/Profile.vue';
import ProfileView from '../views/account/ProfileView.vue';
import ResetPin from '../views/account/ResetPin.vue';




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },

  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration
  },
  {
    path: '/otp-validation',
    name: 'OTPValidation',
    component: OTPValidation
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/mode-of-delivery',
    name: 'DeliveryMode',
    component: DeliveryMode
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: Delivery
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/wishlists',
    name: 'Wishlists',
    component: Wishlists
  },
  {
    path: '/track-order',
    name: 'TrackingOrder',
    component: TrackingOrder
  },
  {
    path: '/select-truck',
    name: 'SelectTruck',
    component: SelectTruck
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/salesorder',
    name: 'SalesOrder',
    component: SalesOrder
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/transfer-fund',
    name: 'TransferFund',
    component: TransferFund
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/complaints',
    name: 'Complaints',
    component: Complaints
  },
  {
    path: '/view-profile',
    name: 'ProfileView',
    component: ProfileView
  },
  {
    path: '/product-details/:id',
    name: 'ProductDetails',
    component: ProductDetails
  },
  {
    path: '/reset-password-success',
    name: 'ForgotPasswordSuccess',
    component: ForgotPasswordSuccess
  },
  {
    path: '/account-statement/:id',
    name: 'AccountStatement',
    component: AccountStatement
  },
  {
    path: '/account-preview/:id',
    name: 'AccountPreview',
    component: AccountPreview
  },
  {
    path: '/contact-us/',
    name: 'ContactUs',
    component: ContactUs
  },

  {
    path: '/reset-pin',
    name: 'ResetPin',
    component: ResetPin
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router