<template>
    <div class="login">
        <div class="container">

            <LogoTop />

            <div class="row">
                <div class="col-sm-6 col-lg-8 offset-lg-2 form-cover">
                    <h1 class="welcome-h1 text-center">Create your account</h1>
                    <p class="text-center"><b>Please enter your credentials correctly to continue.</b></p>

                    <form class="login-form mt-5 reg-form" autocomplete="off" method="post" v-on:submit.prevent="handleRegistration">
                        <div class="row">

                            <div class="col-lg-6 wrapper">
                                <div class="form-group">
                                    <span>Customer Code</span>
                                    <input type="text" class="form-control" placeholder="Customer Code" v-model="register.vendor_id" required>
                                    <i class="far fa-id-badge"></i>
                                </div>
                                
                                <div class="tooltip">Please enter any of your FMN Customer Account numbers</div>
                            </div>


                            <div class="col-lg-6 wrapper">
                                <div class="form-group">
                                    <span>Username</span>
                                    <input type="text" class="form-control" placeholder="Username" v-model="register.username" required title="Please enter a username of your choice">
                                    <i class="far fa-user"></i>
                                </div>
                                
                                <!--  Alphanumeric names are allowed.  -->
                                <div class="tooltip">Spaces and special characters are not permitted</div>
                            </div>


                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Security Question 1</span>

                                    <select type="text" class="form-control select" v-model="register.security_question1" required>
                                        <option selected value="" disabled>Security Question 1</option>
                                        <option :class="register.security_question2 == q.ID ? 'd-none' : ''" v-for="q in security_questions" :key="q.ID" :value="q.ID">{{q.question}}</option>
                                    </select>

                                    <i class="fa fa-fingerprint"></i>
                                </div>
                            </div>
                            

                            <div class="col-lg-6 wrapper">
                                <div class="form-group">
                                    <span>Your Answer to Question 1</span>
                                    <input :type="securityFieldType1" class="form-control" placeholder="Your Answer to  Question 1" v-model="register.security_answer1" required>
                                    <!-- <i class="fa fa-user-shield"></i> -->
                                    
                                    <i @click="securityFieldType1 == 'password' ? securityFieldType1 = 'text' : securityFieldType1 = 'password'" :class="securityFieldType1 == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>
                                </div>
                                <div class="tooltip">Please enter the answer to question 1 you selected.</div>
                            </div>


                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Security Question 2</span>

                                    <select type="text" class="form-control select" v-model="register.security_question2" required>
                                        <option selected="" value="" disabled>Security Question 2</option>
                                        <option :class="register.security_question1 == q.ID ? 'd-none' : ''" v-for="q in security_questions" :key="q.ID" :value="q.ID">{{q.question}}</option>
                                    </select>
                                    <i class="fa fa-fingerprint"></i>
                                </div>
                            </div>


                            <div class="col-lg-6 wrapper">
                                <div class="form-group">
                                    <span>Your Answer to Question 2</span>
                                    <input :type="securityFieldType2" class="form-control" placeholder="Your Answer to Question 2" v-model="register.security_answer2" required>
                                    <!-- <i class="fa fa-user-shield"></i> -->                                    
                                    <i @click="securityFieldType2 == 'password' ? securityFieldType2 = 'text' : securityFieldType2 = 'password'" :class="securityFieldType2 == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>
                                </div>
                                <div class="tooltip">Please enter the answer to question 2 you selected.</div>
                            </div>
                            

                            <div class="col-12">
                                <hr>
                            </div>


                            <div class="col-lg-6 wrapper">
                                <div class="form-group">
                                    <span>Password</span>
                                    <input :type="passwordFieldType" class="form-control" placeholder="Password" v-model="register.password1" required  minlength="8" @keyup="CheckPassword(register.password1)">
                                    <i @click="passwordFieldType == 'password' ? passwordFieldType = 'text' : passwordFieldType = 'password'" :class="passwordFieldType == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>
                                    
                                    <small class="small-hint" :class="pass_stat == 'ok' ? 'text-success bolder' : 'text-danger'">{{pass_policy}}</small>
                                </div>
                                <div class="tooltip">
                                    {{register.password1 ? pass_policy : 'Please set your desired password'}}
                                </div>
                            </div>


                            <div class="col-lg-6 wrapper">
                                <div class="form-group">
                                    <span>Confirm Password</span>
                                    <input :type="passwordFieldType2" class="form-control" placeholder="Confirm Password" v-model="register.password2" required>
                                    <i @click="passwordFieldType2 == 'password' ? passwordFieldType2 = 'text' : passwordFieldType2 = 'password'" :class="passwordFieldType2 == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>
                                </div>
                                <div class="tooltip">Please confirm your desired password</div>
                            </div>

                            <div class="col-lg-6 wrapper">
                                <div class="form-group">
                                    <span>Security PIN</span>
                                    <input type="password" class="form-control" placeholder="Security PIN" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" v-model="register.pin1" required title="Please enter your 4 digits PIN ">
                                    <i class="fa fa-key"></i>
                                </div>
                                <div class="tooltip">{{register.pin1 ? 'Only numbers permitted' : 'Please enter your 4 digits PIN'}}</div>
                            </div>


                            <div class="col-lg-6 wrapper">
                                <div class="form-group">
                                    <span>Confirm Security PIN</span>
                                    <input type="password" class="form-control" placeholder="Confirm Security PIN" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" v-model="register.pin2" required>
                                    <i class="fa fa-key"></i>
                                </div>
                                <div class="tooltip">Confirm your 4 digits PIN</div>
                            </div>
                        </div>

                        <div class="col-lg-8 offset-lg-2" :class="msg_status" v-html="msg_content"></div>

                        <button type="submit" class="btn login-btn col-lg-6 offset-lg-3 col-12" :disabled='isDisabled'>{{processBtn}}</button>

                        <div class="text-center mt-3">
                            <p class="font-14">Already have an account? <a href="/">Login</a></p>
                        </div>
                    </form>

                </div>
            </div>
                
            <Copyright />
        </div>
    </div>
</template>




<script>
import LogoTop from '@/components/LogoTop.vue';
import Copyright from '@/components/Copyright.vue';
import axios from 'axios';

export default {
    name: "Registration",
    components: {
        LogoTop, Copyright
    },
    
    data() {
        return{
            securityFieldType1:'password',
            securityFieldType2:'password',
            passwordFieldType:  'password',
            passwordFieldType2: 'password',
            isDisabled: false,
            processBtn: 'REGISTER',
            msg_status: '',
            msg_content: '',
            register:{
                security_question1:'',
                security_question2:'',
            },
            pass_policy:"",
            pass_stat:"",
            security_questions:[
                {
                    "ID":1,
                    "question":'What is the name of your favorite pet?'
                },
                {
                    "ID":2,
                    "question":'In what city were you born?'
                },
                {
                    "ID":3,
                    "question":'What is the name of your first school?'
                },
                {
                    "ID":4,
                    "question":'What is the name of your favorite Actor?'
                },
                {
                    "ID":5,
                    "question":"What is your mother's maiden name?",
                },
                {
                    "ID":6,
                    "question":'What is the name of your favorite Cousin?'
                },
                {
                    "ID":7,
                    "question":'What was the make of your first car?'
                },
                {
                    "ID":8,
                    "question":'What is your favorite color?'
                },
                {
                    "ID":9,
                    "question":'What City was your high school located?'
                }
            ],
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN E-Commerce | Registration Page";
            },
        },
    },

    methods: {
        async handleRegistration () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';
                

                if(this.register.password1 == this.register.password2){
                    
                    let patt = new RegExp(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/);
                        
                    if(patt.test(this.register.username)){
                        this.msg_status     = 'alert alert-danger mt-4';
                        this.msg_content    = "Username cannot contain special character and white space.";
                        return;
                    }

                    if(this.register.pin1 != this.register.pin2){
                        this.msg_status     = 'alert alert-danger mt-4';
                        this.msg_content    = "Pin & Confirm Pin did not match.";
                        return;
                    }
                    if(this.pass_stat != 'ok'){
                        this.msg_status     = 'alert alert-danger mt-4';
                        this.msg_content    = "Minimum of 8 Characters with at least 1 numeric and 1 special character ";
                        return;
                    }




                
                    this.isDisabled = true,
                    this.processBtn = 'Processing...';

                    let data = this.loadNew(this.register);
                    console.log('i see', data)
                    await axios({
                        method: "post",
                        url: this.$baseurl + "/register",
                        data: {'token':data},
                        headers: {"Content-Type": "application/json"},
                    })

                    .then(res => {
                        this.response = res.data;
                        
                        if(this.response.status){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message;
                            this.processBtn     = 'Loading...';
                        
                            
                            this.$store.commit("set_phone", this.response.phone);
                            this.$store.commit("set_vendor_no", this.response.vendor_no);
                            this.$store.commit("set_email", this.response.emailaddress);

                            // open Otp view;
                            this.$router.push('/otp-validation');

                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = this.response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'REGISTER';
                        }                        
                    })

                    .catch(error => {                        
                        this.is401(error.response.status);
                        console.log(error)
                    })  

                    .finally(e => {                                       
                        this.isDisabled = false;
                        this.processBtn = 'REGISTER';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "Password & Confirm Password did not match.";
                }
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },

        CheckPassword(x) {
            if(x.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)){ 
                this.pass_policy    = "Password strength: Strong";
                this.pass_stat      = "ok"
            }
            else {             
                this.pass_policy    = "Minimum of 8 Characters with at least 1 numeric and 1 special character ";
                this.pass_stat      = ""
            }
        },
    },

};
</script>


<style scoped>
.wrapper {
    cursor: help;
    -webkit-transform: translateZ(0); /* webkit flicker fix */
    -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.wrapper .tooltip {
    background: #21824b;
    bottom: 100%;
    color: #fff;
    display: block;
    margin-bottom: 2px;
    opacity: 0;
    pointer-events: none;
    padding:10px 10px;
    position: absolute;
    width: calc(100% - 20px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
        transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
        transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    text-align: center;
    font-size: 12px !important;
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
    bottom: -20px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
}

/* CSS Triangles - see Trevor's post */
.wrapper .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #21824b 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
}

.wrapper:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
        transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
    display: none;  
}

.lte8 .wrapper:hover .tooltip {
    display: block;
}
</style>