<template>
    <div class="dashboard">

        <!-- added sidebar from component -->
        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <!-- added topnav from components -->
            <TopNav />

            <div class="content-body">

                <!-- page breadcrumb -->
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>Account Statement</b> - {{account_id}}</h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/wallet">Account</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Accounts Statement</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>


                <!-- Tablular Analytics transaction table -->                
                <AccountComponent />


                <FilterModal />
                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import FilterModal from '@/components/FilterModal.vue';
import AccountComponent from '@/components/AccountComponent.vue';


export default {
    name: "AccountStatement",
    components: {
        SideNav, TopNav, Copyright, FilterModal, AccountComponent
    },
    
    data() {
        return{            
            page_active:"wallet",
            account_id:'',
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Account Statement";
            },
        },
    },

    mounted () { 
        this.inactivityTime();        
        this.account_id = this.$route.params.id;
    },

    methods: {
       
    },

    created () {
        
    },

    computed: {
    },
};
</script>

<style scoped>
label{
    position: absolute;
    top: -15px;
    font-weight: 500;
    font-size: 12px;
}
</style>

